.adam-text-and-image {
	display: flex;

	flex-direction: row-reverse;
	&.adam-text-and-image--image-right {
		flex-direction: row;
	}

	flex-wrap: wrap;
	.adam-text-and-image__text {
		margin-bottom: $margin-bottom;
	}
	@include small-breakpoint {
		flex-wrap: nowrap;
		.adam-text-and-image__text {
			margin-top: auto;
			margin-bottom: auto;
		}
	}

	.adam-text-and-image__text {
		flex-grow: 1;
	}

	@include small-breakpoint {
		.adam-text-and-image__image {
			max-width: calc(50% - 1rem);
		}
		.adam-text-and-image__text {
			flex-basis: 50%;
		}
		.adam-text-and-image__text {
			margin-left: $column-padding;
		}
		.adam-text-and-image__image {
			margin-right: $column-padding;
		}
		&.adam-text-and-image--image-right {
			.adam-text-and-image__text {
				margin-left: 0;
				margin-right: $column-padding;
			}
			.adam-text-and-image__image {
				margin-right: 0;
				margin-left: $column-padding;
			}
		}
	}

	.adam-text-and-image__image {
		line-height: 0;
		img {
			max-width: 100%;
		}
	}
}
