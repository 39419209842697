@include only-small-breakpoint {
	.hide-mobile {
		display: none !important;
	}
}

@include medium-breakpoint {
	.hide-desktop {
		display: none !important;
	}
}

@each $value, $multiplier in $spacing-scale {
	.padding-top-#{$value} {
		padding-top: $mobile-spacing-scale-unit * $multiplier;
	}
	.padding-bottom-#{$value} {
		padding-bottom: $mobile-spacing-scale-unit * $multiplier;
	}
	.margin-top-#{$value} {
		margin-top: $mobile-spacing-scale-unit * $multiplier;
	}
	.margin-bottom-#{$value} {
		margin-bottom: $mobile-spacing-scale-unit * $multiplier;
	}
}

@include small-breakpoint {
	@each $value, $multiplier in $spacing-scale {
		.padding-top-#{$value} {
			padding-top: $spacing-scale-unit * $multiplier;
		}
		.padding-bottom-#{$value} {
			padding-bottom: $spacing-scale-unit * $multiplier;
		}
		.margin-top-#{$value} {
			margin-top: $spacing-scale-unit * $multiplier;
		}
		.margin-bottom-#{$value} {
			margin-bottom: $spacing-scale-unit * $multiplier;
		}
	}
}

.align-items-center {
	align-items: center;
}
.align-items-bottom {
	align-items: flex-end;
}
