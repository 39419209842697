//
// TEXT
//
.text-primary {
	color: $primary-color;
}
.text-white {
	color: white;
}

.button-round-button {
	@extend .button;
	@extend .round-button;
}

.button-muted-button-round-button {
	@extend .button;
	@extend .muted-button;
	@extend .round-button;
}

.button-accent-button-round-button {
	@extend .button;
	@extend .accent-button;
	@extend .round-button;
}

.img-left{
    padding-left: 0;
    .adam-image{
        text-align: left;
    }
}

.button-close{
    width: 30px;
    height: 30px;
    color: black !important;
    border-radius: 30px;
    border: none;
    font-weight: bold;
}

.icon-close{
    background-image: url("data:image/svg+xml,%3Csvg height='20px' viewBox='0 0 365.696 365.696' width='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center; 
    background-color: $alternate-background;
    margin-bottom: 0;
	background-size: 40%;
    &:active, &:hover, &:focus{
		cursor: pointer;
        color: black !important;
        border: none;
    }
}
