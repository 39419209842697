p,
a,
span {
	line-height: $line-height;
}

@include small-breakpoint {
	p:last-child {
		margin-bottom: 0;
	}
}

// Heading individual styles
.h1 {
	font-size: $h1-mobile;
}

.h2 {
	font-size: $h2-mobile;
}

.h3 {
	font-size: $h3-mobile;
}

.h4 {
	font-size: $h4-mobile;
}

.h5 {
	font-size: $h5-mobile;
}

@include small-breakpoint {
	// Heading desktop individual styles
	.h1 {
		font-size: $h1;
	}

	.h2 {
		font-size: $h2;
	}

	.h3 {
		font-size: $h3;
	}

	.h4 {
		font-size: $h4;
	}

	.h5 {
		font-size: $h5;
	}
}
