// Variabili personalizzate

$alternate-background	: #e0e0e0 ;
$arancio : #ffa333 !important;
$azzurro-scuro : #172d40 !important;
$scurissimo : #0e1b26 !important;
$azzurro-intermedio : #304c66 !important;
$azzurro-chiaro: #365673 !important;
$grigio-chiaro: #f9f9f9 !important;
$grigio-scuro: #e1e1e1 !important;
$bianco: #ffffff !important;
$grey: #f9f9f9;
$nero-finto: #404040 !important;

$x-small                  : 600px                                      !default;
$small                    : 768px                                      !default;
$medium                   : 1024px                                     !default;
$large                    : 1200px                                     !default;

$primary-color            : #ffa333                                    !default;
$secondary-color          : #29de7d                                    !default;
$alternate-background     : #fafafa                                    !default;

$background-colors: (
	"primary": $primary-color,
	"secondary": $secondary-color,
    "grey": $grey,
	"bianco": $bianco,
	"grigio-chiaro": $grigio-chiaro,
	"grigio-scuro": $grigio-scuro,
    "blu-scuro": $azzurro-scuro,
    "blu-nero": $scurissimo,
    "azzurro-scuro": $azzurro-scuro,
    "azzurro-intermedio": $azzurro-intermedio,
    "azzurro-chiaro": $azzurro-chiaro,
    "arancio": $arancio,
    "scurissimo": $scurissimo,
) !default;
