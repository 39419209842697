body {
    font-size: inherit;
}

// .container .full-container {
// 	padding: 0;
// }
// @include only-small-breakpoint {
// 	.full-container .container {
// 		padding: 0;
// 	}
// }

// wrapper è la classe usata da Proteinic.Adam.NodeTypes:Content.OneColumn
.wrapper {
    // Create a new stacking context
    display: flex;
    // Make child of .wrapper span the full breadth
    .neos-contentcollection {
        width: 100%;
    }
}

// @include large-breakpoint {
	#{$has-background} {
		padding: $background-padding;
	// 	.adam-text-and-image {
	// 		margin: -$background-padding;
	// 		.adam-text-and-image__text {
	// 			padding: $background-padding;
	// 		}
	// 	}
	}
	.background-,.background-none{
		padding: 0;
	}
// }
// #{$has-background} {
// 	padding-top: $background-padding;
// 	padding-bottom: $background-padding;
// 	.adam-text-and-image {
// 		margin-top: -$background-padding;
// 		margin-bottom: -$background-padding;
// 		.adam-text-and-image__text {
// 			padding-top: $background-padding;
// 			padding-bottom: $background-padding;
// 			margin-bottom: 0;
// 		}
// 	}
// }

.flex-row {
	align-items: inherit;
}

main > .neos-contentcollection > .full-container > #{$has-background} {
	padding-left: 0;
	padding-right: 0;
	> .flex-row {
		margin: 0;
		padding: 0 $container-padding - $padding;
	}
}

// .neos-contentcollection {
// 	display: flex;
// 	flex-direction: column;
// }

@include only-small-breakpoint {
	.flex-large:last-child {
		margin-bottom: 0;
	}
}

.adam-text-and-image-container {
	#{$has-background} {
		padding: 0;
		.adam-text-and-image__text {
			padding: $background-padding;
			padding-left: 0;
		}
		.adam-text-and-image--image-right .adam-text-and-image__text {
			padding-left: $background-padding;
			padding-right: 0;
		}
	}
}


.twoThirdsRight .column-one,
.twoThirdsLeft .column-two {
    width: 40% ;
}

.twoThirdsRight .column-two,
.twoThirdsLeft .column-one {
    width: 60% ;
}

@media (min-width: 800px) {
    .twoThirdsRight > .flex-small.two-columns-column, 
    .twoThirdsLeft > .flex-small.two-columns-column {
        flex-basis: unset !important;
    }
}
