// Stili custom

* {
    font-family: "Titillium Web" !important;
    scroll-behavior: smooth !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}
.big-text {
    font-size: 50px;
}
p,
span {
    line-height: 1.2;
}
main {
    overflow: hidden;
}

.bottone-arancio,
.bottone-bianco {
    @extend .button;
    border-radius: 0;
    padding: 20px 40px;
    transition: all 0.2s;
    font-weight: 100;
    font-size: 12px;
    border: 0 !important;
}
.bottone-arancio {
    color: white;
    background-color: $arancio;
    border-color: $arancio;
    position: relative;
    padding-left: 60px;
    z-index: 120;
    &::before {
        transition: all 0.2s;
        content: "";
        position: absolute;
        width: 20px;
        height: 2px;
        bottom: 50%;
        left: 22px;
        z-index: 1;
        background-color: white;
    }
}
.bottone-bianco {
    color: black;
    background-color: white;
    border-color: transparent;
    position: relative;
    padding-left: 60px;
    z-index: 120;
    &::before {
        transition: all 0.2s;
        content: "";
        position: absolute;
        width: 20px;
        height: 2px;
        bottom: 50%;
        left: 22px;
        z-index: 1;
        background-color: black;
    }
}

.bottone-arancio:hover {
    color: $arancio !important;
    background-color: white !important;
    border-color: $arancio !important;
    border: 0 !important;
    &::before {
        background-color: $arancio;
    }
}
.bottone-bianco:hover {
    color: white;
    background-color: $arancio;
    border: 0 !important;
    &::before {
        background-color: white;
    }
}
.nero-finto {
    color: $nero-finto;
}
.arancio,
.arancio-tratto-ambedue,
.arancio-tratto-destra,
.arancio-tratto-sinistra {
    color: $arancio;
    position: relative;
}
.bianco-tratto-sinistra {
    color: white;
    position: relative;
}

.small-text {
    font-size: 14px;
}

.mtn-12 {
    margin-top: -90px;
    .flex-small {
        z-index: 12;
    }
    @media screen and (max-width: $medium) {
        margin-top: -60px; 
    }
    @media screen and (max-width: $small) {
        margin-top: 30px; 
    }
}

.lista-ancore {
    ul {
        list-style: none;
        font-size: 17px;
        padding: 0;
        text-align: center;
        a {
            color: white !important;
        }
    }
    .background-azzurro-chiaro {
        &:hover {
            background-color: $azzurro-scuro;
        }
    }
}

.absolute {
    position: absolute !important;
    z-index: -10;
}

[class*=" icon-"]:before,
[class^="icon-"]:before {
    color: $grigio-scuro;
}

.box-quadrati {
    line-height: 22px;
    h3 {
        max-width: 250px;
    }
    .neos-contentcollection {
        z-index: 5;
        position: relative;
        .adam-column {
            overflow: hidden;
            position: relative;
            padding: 30px 20px;
            min-height: 240px;
            border: 1px solid transparent;
            -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            background-color: white;
            transition: all 0.3s;
            .neos-contentcollection {
                z-index: -1;
            }
        }
        .flex-row.two-columns {
            margin-right: 20px;
            margin-top: -15px;
            .adam-column {
                -webkit-box-shadow: unset;
                box-shadow: unset;
                background-color: unset;
                transition: unset;
                .adam-image {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 80%;
                    overflow: hidden;
                }
            }
        }
    }
}

.box-shadow {
    .neos-contentcollection {
        .adam-column {
            overflow: hidden;
            position: relative;
            min-height: 240px;
            -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            transition: all 0.3s;
        }
    }
}

.box-shadow-single {
    overflow: hidden;
    position: relative;
    min-height: 240px;
    -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
    transition: all 0.3s;
}

.bianco-tratto-sinistra,
.arancio-tratto-destra {
    margin-left: 60px;
}

.arancio-tratto-ambedue:before,
.arancio-tratto-destra:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    top: 45%;
    left: -60px;
    z-index: 1;
    background-color: $arancio;
}
.bianco-tratto-sinistra:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    top: 45%;
    left: -60px;
    z-index: 1;
    background-color: white;
}

.arancio-tratto-ambedue:after,
.arancio-tratto-sinistra:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    top: 45%;
    right: -60px;
    z-index: 1;
    background-color: $arancio;
}

#contact,
#newsletter {
    input,
    select {
        margin-bottom: 10px;
    }
    select{
        line-height: 1.6;
    }
    color: white;
    label {
        margin-bottom: 2px;
        font-weight: 300 !important;
    }
    .form-fields {
        // display: flex;
        // flex: 0 0 50% !important;
        // flex-wrap: wrap;
        .clearfix {
            margin: auto;
            margin-left: 0;
            &.error{
                position: relative;
                margin-bottom: 40px !important;
                .help-inline{
                    position: absolute;
                }
            }
            &:nth-child(7) {
                display: flex;
                flex-direction: row-reverse;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 20px;
                float: left;
                margin-left: 0px;
                .input.clearfix {
                    padding-bottom: 25px;
                }
                .help-inline {
                    position: absolute;
                }
                label {
                    margin-right: 10px;
                }
            }
        }
    }
    .btn.btn-primary {
        background-color: $arancio;
        border-radius: 0;
        font-size: 16px;
        font-weight: 100;
        width: 170px;
    }
}
#newsletter {
    .form-fields {
        .clearfix {
            &:nth-child(3) {
                display: flex;
                flex-direction: row-reverse;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: 0px;
                float: left;
                label {
                    margin-right: 10px;
                }
            }
        }
    }
}
@media (min-width: $small) {
    .doppio-grigio {
        .background-alternate {
            background: linear-gradient(
                90deg,
                $grigio-chiaro 50%,
                $grigio-scuro 50%
            );
        }
    }
}
.immagine-cover {
    div {
        min-height: 330px;
    }
}
.immagine-cover {
    position: relative;
    overflow: hidden;
    .adam__text-wrapper__text {
        z-index: 3;
        h1 {
            margin-bottom: 0px;
        }
        .bottone-bianco {
            margin-top: 20px;
        }
        // &::after {
        //     content: "";
        //     position: absolute;
        //     width: 360px;
        //     height: 360px;
        //     min-width: 100px;
        //     min-height: 100px;
        //     top: 15%;
        //     left: 10%;
        //     border-radius: 100%;
        //     right: 100px;
        //     z-index: -10;
        //     background-color: $arancio;
        //     content: "";
        //     vertical-align: middle;
        // }
    }
}

.lato-arancio {
    position: relative;
    padding-left: 20px;
    &::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        background-color: $arancio;
    }
}

@media (min-width: 800px) {
    .site-navbar > * {
        flex-grow: unset;
    }
}

@media (min-width: $large) {
    // .immagine-cover {
    //     .adam__text-wrapper__text {
    //         &::after {
    //             width: 500px;
    //             height: 500px;
    //             top: 15%;
    //             left: 15%;
    //         }
    //     }
    // }
}

@media (max-width: $large) {
    .box-quadrati {
        line-height: 22px;
        h3 {
            max-width: 170px;
        }
    }
}

@media (max-width: $small) {
    .immagine-cover {
        img {
            width: unset !important;
        }
    }
}

.numeri-rossi {
    ol {
        display: flex;
        flex: 0 50%;
        flex-direction: column;
        line-height: 1.3;
        li {
            padding-right: 50px;
            font-size: 25px;
            &::marker {
                color: $arancio;
                font-size: 20px;
            }
        }
    }
}

.lista-arancio {
    ul {
        list-style-type: none;
        li {
            position: relative;
            margin-left: 20px;
            &::before {
                content: "";
                position: absolute;
                width: 15px;
                height: 2px;
                bottom: 10px;
                left: -20px;
                z-index: 1;
                background-color: $arancio;
            }
        }
    }
}

.secondary-menu {
    display: none !important;
}

.language-menu {
    display: none !important;
}

.site-navbar {
    position: relative;
    padding: 20px 0px;
    ul {
        margin-left: auto;
    }
    @media screen and (max-width:380px) {
        .brand{
            img{
                max-width: 180px;
            }
        }
    }
}

.pre-menu {
    display: flex;
    top: 0;
    position: absolute;
    right: 10px;
    z-index: 100;
    width: 50% !important;
    align-items: center;
    justify-content: flex-end;
    .immagine-navbar {
        width: 40px;
        margin-right: 10px;
    }
    .testo-navbar {
        width: 170px;
    }
    .background-arancio,.background-primary {
        background-color: $arancio;
        padding: 10px 10px;
        margin-right: 0;
        width: 170px;
        font-size: 14px;
        font-weight: 300;
    }
}
a:hover {
    color: $arancio;
}
a.menu-item--current {
    color: $arancio !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
}

.lista-custom {
    ul {
        padding-left: 20px;
        list-style-type: square;
        line-height: 1.6;
    }
    li::marker {
        color: $arancio;
    }
}

.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    ul {
        padding-left: 0px !important;
    }
}

.py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

@media (max-width: 767px) {
    .site-navbar {
        ul {
            text-align: right;
            li {
                text-align: right;
                a {
                    padding: 12px 0 0 0 !important;
                }
            }
        }
    }

    .colonna-con-immagine {
        .three-columns-column, .four-columns-column {
            .neos-contentcollection {
                z-index: 5;
                position: relative;
                .adam-column {
                    .adam-image {
                        position: absolute;
                        min-width: 80px;
                        width: 10%;
                        right: -10px;
                        top: -60px;
                    }
                }
            }
        }
    }
}

.loghi-social {
    .three-columns {
        width: 50%;
        .three-columns-column {
            padding: 0 !important;
        }
    }
}

.news-pagination {
    padding-top: 20px;
}

.news-list {
    .flex-large {
        margin-bottom: 40px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 16px;
        padding: 10px 0px 20px 0px;
    }
    p.float-right {
        font-size: 14px;
    }
}

.news-istituto {
    .three-columns-column {
        position: relative;
    }
    h4 {
        font-weight: unset !important;
    }
    section {
        -webkit-box-shadow: 0px -10px 14px -4px rgba(0, 0, 0, 0.19);
        box-shadow: 0px -10px 14px -4px rgba(0, 0, 0, 0.19);
    }
    .adam-text {
        -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
        box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
    }
    p {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        color: $arancio;
        background: #172d40;
        padding: 5px 10px;
    }
    .news-category {
        display: none;
    }
    .news-data {
        padding-top: 60px;
    }
}

.margine-icona {
    margin-top: 3px !important;
}

.news-date {
    position: relative;
}

.news-no-img {
    .three-columns-column {
        position: relative;
        section {
            display: none !important;
        }
        .adam-text {
            padding-top: 70px;
            -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
        }
    }
    h4 {
        font-weight: unset !important;
    }
    p {
        position: absolute;
        color: $arancio;
        z-index: 20;
        top: 40px;
        left: 9%;
    }
    .news-category {
        display: unset !important;
    }
}

.news-facolta {
    .three-columns-column {
        position: relative;
        .adam-text {
            -webkit-box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
            box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
        }
    }
    h4 {
        font-weight: unset !important;
    }
    p {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0%;
        font-size: 18px !important;
        color: $arancio;
        background: #172d40;
        padding: 5px 10px;
    }
    .news-category {
        margin-top: 0;
        display: unset !important;
    }
    .news-data {
        padding-top: 60px;
    }
}
.news-pagination {
    justify-content: center;
    text-align: center;
    display: flex;
    width: 30%;
    margin: auto;
    align-items: center;
    span {
        padding: 0px 20px;
    }
    a {
        margin: 0;
    }
}
.news-category {
    background-color: $azzurro-chiaro;
}

.news-data {
    background-color: white;
    display: flex;
    flex-direction: column;
    display: flex;
    min-height: 150px;
    .news-info {
        flex: 1;
    }
}

.news-alimentazione {
    @extend .news-no-img;
    .news-list {
        flex-direction: column;
    }

    p {
        z-index: 10;
        position: absolute;
        top: 5%;
        left: 0;
        color: $arancio;
        background: #172d40;
        padding: 5px 10px;
        font-size: 18px !important;
    }
    .news-data {
        padding-top: 50px !important;
    }
}

ul.menu.menu--level-1 {
    z-index: 30;
    list-style-type: disc !important;
    color: $arancio !important;
    padding-left: 40px !important;
    li {
        padding-left: 0px !important;
    }
}

@media (max-width: $large) {
    .fix-colonna {
        .two-columns-column {
            flex-basis: 100% !important;
            margin-bottom: 20px;
        }
    }
}

form {
    a {
        color: white !important;
        &:hover {
            color: white !important;
        }
    }
}

@media (max-width: $medium) {
    ul.menu.menu--level-0 {
        font-weight: 700;
    }
    span.menu-item-link {
        padding-bottom: 0;
    }
    ul.menu.menu--level-1 {
        font-weight: 300 !important;
        list-style-type: none !important;
        color: $arancio !important;
        background-color: white !important;
        padding-left: 40px !important;
        padding: 0 !important;
        li {
            padding-right: 0 !important;
        }
    }
    .loghi-social {
        .three-columns {
            width: 50%;
            .three-columns-column {
                flex: 50% !important;
            }
        }
    }
}

.cinque-immagini {
    .neos-contentcollection {
        display: flex;
    }
}

@media (max-width: $small) {
    .cinque-immagini {
        .neos-contentcollection {
            display: block;
        }
    }
    .loghi-social {
        .three-columns {
            display: flex;
            flex-wrap: nowrap;
        }
    }
    .intestazione-titoli {
        .flex-small.four-columns-column {
            flex-basis: 100%;
            margin-bottom: 20px;
        }
    }
}
@media (min-width: $small) and (max-width: $large) {
    .intestazione-titoli {
        .flex-small.four-columns-column {
            flex-basis: 50%;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: $medium) {
    .pre-menu {
        display: none;
    }
    .site-navbar {
        button {
            display: block !important;
            flex-grow: unset !important;
        }
        .hide-mobile {
            display: none !important;
        }
        .menu--level-0 {
            font-weight: 700;
            margin-bottom: 0;
            text-align: right;
            list-style: none;
            margin: 0;
            li {
                span,
                a {
                    height: 30px;
                    padding-top: 10px !important;
                }
            }
        }
    }
    .cinque-immagini {
        .neos-contentcollection {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            section {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    flex: 33.33% 0;
                }
                &:nth-child(4),
                &:nth-child(5) {
                    flex: 50% 0;
                }
            }
        }
    }
}

.main-three-columns {
    .three-columns {
        .column1 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #172d40 !important;
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        .column2 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #304c66 !important;               
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        .column3 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #365673 !important;
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
    }
}


.main-four-columns {
    @media screen and (min-width: $large) {
        max-width: 1500px;
    }
    .four-columns {
        h2{
            font-size: 27px;
        }
        .column1 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #172d40 !important;
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        .column2 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #365673 !important;               
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        .column3 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #365673 !important;
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        .column4 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #172d40 !important;
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        @media screen and (max-width: $large) {
            .flex-small.four-columns-column{
                flex-basis: 50%;
                margin-bottom: 2rem;
            }
        }
        @media screen and (max-width: $small) {
            .flex-small.four-columns-column{
                flex-basis: 100%;
                margin-bottom: 2rem;
            }
        }
    }
}


.secondary-three-columns {
    .three-columns {
        .column1 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #304c66 !important;
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        .column2 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #304c66 !important;               
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
        .column3 {
            .neos-contentcollection {
                padding: 2rem;
                background-color: #304c66 !important;
                .neos-contentcollection {
                    padding: 0rem;
                }
            }
        }
    }
}