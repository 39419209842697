
.intro {
    .overlay{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 110;
    }
    .dark-overlay {
        background-color: black;
        opacity: 0;
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 110;
        transition: opacity 0.5s ease-in-out;
        &.active {
            display: block;
        }
        &.showing {
            opacity: 0.6;
        }
    }
    .intro-box {
        position: fixed;
        z-index: 200;
        padding: 20px 30px;
        opacity: 0;
        background-color: white;
        width: 700px;
        left: calc(50%-330px);
        max-width: 600px;
        margin: auto;
        display: none;
        transition: opacity 0.5s ease-in-out;
        top: 80px;

        @media screen and (max-width: $medium) {
            width: 550px;
            left: calc(50%-250px);
        }
        @media screen and (max-width: $small) {
            width: 360px;
            top: 50px;
            left: calc(50%-180px);
        }
        @media screen and (max-width: $x-small) {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        .icon-close {
            position: absolute;
            right: 12px;
            top: 12px;
        }
        &-title {
            text-align: center;
            margin-bottom: 20px;
            @media screen and (max-width: $small) {
                .arancio-tratto-ambedue{
                    &::before{
                        width: 20px;
                        left: -30px;
                    }
                    &::after{
                        width: 20px;
                        right: -30px;
                    }
                }
                
            }
        }
        &-image {
            text-align: center;
            margin-bottom: 40px;
            margin-top: 20px;
            img{
                max-width: 300px;
            }
        }
        &-outro {
            text-align: center;
            margin-bottom: 30px;
        }
    }
}

.intro {
    &.active {
        .dark-overlay, .intro-box {
            display: block;
        }
    }
    &.showing {
        .intro-box {
            opacity: 1;
        }
        .dark-overlay {
            opacity: 0.6;
        }
    }
}

.noScroll{
    position: fixed;
    width: 100%;
    overflow-y: scroll;
}